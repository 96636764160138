import { forEach } from 'lodash'
import { crudService } from '../_services'
import { stringUtils } from 'utils'

export const relatorioAction = {
  getRelatorioChecklist,
  getRelatorioChecklistOperacao,
  getRelatorioCPVABC,
  getRelatorioCompras,
  getRelatorioConversoes,
  getRelatorioNivelServico,
  getRelatorioPonto,
  getRelatorioPrecos,
  getRelatorioRanking,
  getRelatorioTotvsInsumosUnidadesFornecedores,
  getRelatorioTroca,
  getRelatorioVendasInsumos,
  onChangeProps,
  clear,
  clearAll,
}

function getRelatorioChecklist(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/checklist/' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log(err);
        return err;
      })
  }
}

function getRelatorioChecklistOperacao(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/checklistOperacao/'

    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        let relatorio = []

        if (response.data) {
          const checklistMap = response.data.checklistMap
          const checklistInsumoUnidadeMap = response.data.checklistInsumoUnidadeMap

          for (const key in checklistMap) {
            if (checklistMap.hasOwnProperty(key) && checklistInsumoUnidadeMap.hasOwnProperty(key)) {
              let checklistObj = {
                checklist: checklistMap[key],
                checklistsTotvsInsumosUnidades: checklistInsumoUnidadeMap[key]
              };

              relatorio.push(checklistObj)
            }
          }

          dispatch(notIsLoading())
          dispatch(changeRelatorioList(relatorio, null))
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      })
  }
}

function getRelatorioCPVABC(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/cpv/abc' + stringUtils.serialize(payload);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log(err);
        return err;
      })
  }
}

function getRelatorioCompras(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())

    let apiEndpoint = 'relatorios/compras'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log('Error', err);
        return err;
      })
  }
}

function getRelatorioConversoes(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/conversoes' + stringUtils.serialize(payload);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log(err);
        return err;
      })
      .finally(() => {
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioNivelServico(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/nivelServico' + stringUtils.serialize(payload);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log(err);
        return err;
      })
  }
}

function getRelatorioPonto(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/pontos' + stringUtils.serialize(payload);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getRelatorioPrecos(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/precos' + stringUtils.serialize(payload);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getRelatorioRanking(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/ranking' + stringUtils.serialize(payload);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        dispatch(notIsLoading())
        console.log(err.response);
        return err.response;
      })
  }
}

function getRelatorioTotvsInsumosUnidadesFornecedores(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/totvsInsumosUnidadesFornecedores' + stringUtils.serialize(payload);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getRelatorioTroca(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/trocas' + stringUtils.serialize(payload);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getRelatorioVendasInsumos(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/vendasInsumos' + stringUtils.serialize(payload);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeRelatorioList(response.data, null))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event))
  }
}

export function changeRelatorioList(relatorio, totalRows) {
  return {
    type: 'FETCHED_ALL_RELATORIO',
    relatorio: relatorio,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'RELATORIO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'RELATORIO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'RELATORIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function isLoading() {
  return {
    type: 'RELATORIO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'RELATORIO_NOTISLOADING'
  }
}
