import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { deepOrange } from '@material-ui/core/colors';
import { AccessControl } from '../../components/Utils/AccessControl'
import ImageUploading from 'react-images-uploading'
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import HelpIcon from '@material-ui/icons/Help'
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography'
import { ClienteFidelizacaoModal } from './clienteFidelizacaoModal.component';
import { ModalClientesTransferirPontos } from './modalClientesTransferirPontos.component';
import { ModalClientesAdicionarPontosManualmente } from './modalClientesAdicionarPontosManualmente.component';
import { ModalClientesAdicionarTrocasManualmente } from './modalClientesAdicionarTrocasManualmente.component';
import {
  Avatar,
  Backdrop,
  Button,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  MenuItem,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Input
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  clienteAction,
  cidadeAction,
  estadoAction,
  produtoAction,
  pontuacaoAction,
  trocaAction,
  notificacaoUsuarioAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { cepMask, cpfMask, phoneMask } from '../../utils'
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import { Page } from 'components'
import { isEmpty } from 'lodash'
import { errorsUploadImage } from 'utils'
import getInitials from 'utils/getInitials'
import moment from 'moment'
import { useDebounce } from 'use-debounce';
import { useConfirm } from 'material-ui-confirm'
import { toast } from 'react-toastify'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ClienteEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const { classes, cliente, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [images] = useState([])
  const [imagem, setImagem] = useState('')
  const [open] = useState(false)
  const [, forceUpdate] = React.useState(0);
  const [idFidelizacao, setIdFidelizacao] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [isEditingObservacao, setIsEditingObservacao] = useState(false)

  const estados = useSelector((state) => state.estado.estado)
  const { cidade } = useSelector((state) => state.cidade)

  //const [pageFidelizacao, setPageFidelizacao] = useState(0);
  //const [limitFidelizacao, setLimitFidelizacao] = useState(10);
  //const [termFidelizacao, setTermFidelizacao] = useState('');
  //const [debounceTermFidelizacao] = useDebounce(termFidelizacao, 1000);
  //const [orderFidelizacao] = useState('dataHora');
  //const [directionFidelizacao] = useState('desc');
  //const totalRegistrosFidelizacao = props.fidelizacao.totalRegistros

  const [pagePontuacao, setPagePontuacao] = useState(0);
  const [limitPontuacao, setLimitPontuacao] = useState(10);
  const [termPontuacao, setTermPontuacao] = useState('');
  const [debounceTermPontuacao] = useDebounce(termPontuacao, 1000);
  const [orderPontuacao] = useState('dataHora');
  const [directionPontuacao] = useState('desc');
  const totalRegistrosPontuacao = props.pontuacao.totalRegistros

  const [pageTroca, setPageTroca] = useState(0);
  const [limitTroca, setLimitTroca] = useState(10);
  const [termTroca, setTermTroca] = useState('');
  const [debounceTermTroca] = useDebounce(termTroca, 1000);
  const [orderTroca] = useState('dataHora');
  const [directionTroca] = useState('desc');
  const totalRegistrosTroca = props.troca.totalRegistros

  const [pageProduto, setPageProduto] = useState(0);
  const [limitProduto, setLimitProduto] = useState(10);
  const [termProduto, setTermProduto] = useState('');
  const [debounceTermProduto] = useDebounce(termProduto, 1000);
  const [orderProduto] = useState('total');
  const [directionProduto] = useState('desc');
  const totalRegistrosProduto = props.produto.totalRegistros

  const [pageNotificacao, setPageNotificacao] = useState(0);
  const [limitNotificacao, setLimitNotificacao] = useState(10);
  const [termNotificacao, setTermNotificacao] = useState('');
  const [debounceTermNotificacao] = useDebounce(termNotificacao, 1000);
  const [orderNotificacao] = useState('dataHora');
  const [directionNotificacao] = useState('desc');
  const totalRegistrosNotificacao = props.notificacaoUsuario.totalRegistros

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const { fidelizacao } = useSelector(
    (state) => state.fidelizacao
  )

  const { pontuacao } = useSelector(
    (state) => state.pontuacao
  )

  const { notificacaoUsuario } = useSelector(
    (state) => state.notificacaoUsuario
  )

  useEffect(() => {
    if (params.id) {
      dispatch(clienteAction.getClienteById(params.id))
      dispatch(estadoAction.getEstadoSelect())     

    } else {
      dispatch(clienteAction.clear())
      setImagem(null)
    }

    setIsEditingObservacao(!props.cliente.observacao)
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(clienteAction.getClienteById(params.id))
    }

    let filterModel = {
      Page: pageProduto,
      Limit: limitProduto,
      Term: termProduto,
      Order: orderProduto,
      Direction: directionProduto,
      IdCliente: params.id
    };

    dispatch(produtoAction.getProdutoCliente(filterModel, true));

  }, [props.pontuacao.pontuacao.length, props.troca.troca.length])

  const handleVerificaTelefoneCliente = (n) => {
    confirm({
      title: 'Você deseja verificar este telefone?',
      description: 'O status verificado será alterado para "SIM"',
      confirmationText: 'Sim, verificar!',
      disabled: props.cliente.isLoading,
      confirmationText: props.cliente.isLoading ? 'Verificando telefone...' : 'Sim, verificar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(clienteAction.verifyClienteTelefone(n.id))
    })
  }

  const handleAprovarTroca = (n) => {
    confirm({
      title: 'Você deseja aprovar esta troca?',
      description: 'O status será alterado para "PROCESSADO"',
      confirmationText: 'Sim, aprovar!',
      disabled: props.troca.isLoading,
      confirmationText: props.troca.isLoading ? 'Aprovando troca...' : 'Sim, aprovar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(trocaAction.approveTroca(n, params.id))
    })
  }

  const handleVerificaEmailCliente = (n) => {
    confirm({
      title: 'Você deseja verificar este e-mail?',
      description: 'O status verificado será alterado para "SIM"',
      confirmationText: 'Sim, verificar!',
      disabled: props.cliente.isLoading,
      confirmationText: props.cliente.isLoading ? 'Verificando e-mail...' : 'Sim, verificar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(clienteAction.verifyClienteEmail(n))
    })
  }

  useEffect(() => {

    const fetchData = (pagePontuacao, limitPontuacao, termPontuacao) => {

      let filterModel = {
        Page: pagePontuacao,
        Limit: limitPontuacao,
        Term: termPontuacao,
        Order: orderPontuacao,
        Direction: directionPontuacao,
        IdCliente: params.id
      };

      dispatch(pontuacaoAction.getPontuacao(filterModel, true));

    };

    fetchData(pagePontuacao, limitPontuacao, debounceTermPontuacao, orderPontuacao, directionPontuacao);
  }, [pagePontuacao, limitPontuacao, debounceTermPontuacao, orderPontuacao, directionPontuacao]);

  //useEffect(() => {

  //  const fetchData = (pageFidelizacao, limitFidelizacao, termFidelizacao) => {

  //    let filterModel = {
  //      Page: pageFidelizacao,
  //      Limit: limitFidelizacao,
  //      Term: termFidelizacao,
  //      Order: orderFidelizacao,
  //      Direction: directionFidelizacao,
  //      IdCliente: params.id
  //    };

  //    dispatch(fidelizacaoAction.getFidelizacao(filterModel, true));

  //  };

  //  fetchData(pageFidelizacao, limitFidelizacao, debounceTermFidelizacao, orderFidelizacao, directionFidelizacao);
  //}, [pageFidelizacao, limitFidelizacao, debounceTermFidelizacao, orderFidelizacao, directionFidelizacao]);

  useEffect(() => {

    const fetchData = (pageNotificacao, limitNotificacao, termNotificacao) => {

      let filterModel = {
        Page: pageNotificacao,
        Limit: limitNotificacao,
        Term: termNotificacao,
        Order: orderNotificacao,
        Direction: directionNotificacao,
        IdCliente: params.id
      };

      dispatch(notificacaoUsuarioAction.getNotificacoesByCliente(filterModel, true));
      //  console.log("props.notificacaoUsuario.notificacaoUsuario", props.notificacaoUsuario.notificacaoUsuario);

    };

    fetchData(pageNotificacao, limitNotificacao, debounceTermNotificacao, orderNotificacao, directionNotificacao);
  }, [pageNotificacao, limitNotificacao, debounceTermNotificacao, orderNotificacao, directionNotificacao]);


  useEffect(() => {

    const fetchData = (pageTroca, limitTroca, termTroca) => {

      let filterModel = {
        Page: pageTroca,
        Limit: limitTroca,
        Term: termTroca,
        Order: orderTroca,
        Direction: directionTroca,
        IdCliente: params.id
      };

      dispatch(trocaAction.getTroca(filterModel, true));

    };

    fetchData(pageTroca, limitTroca, debounceTermTroca, orderTroca, directionTroca);

  }, [pageTroca, limitTroca, debounceTermTroca, orderTroca, directionTroca]);

  const handleOpenModal = (idFidelizacao) => {
    toggle();

    setIdFidelizacao(idFidelizacao)
  }

  useEffect(() => {

    const fetchData = (pageProduto, limitProduto, termProduto) => {

      let filterModel = {
        Page: pageProduto,
        Limit: limitProduto,
        Term: termProduto,
        Order: orderProduto,
        Direction: directionProduto,
        IdCliente: params.id
      };

      dispatch(produtoAction.getProdutoCliente(filterModel, true));

    };

    fetchData(pageProduto, limitProduto, debounceTermProduto, orderProduto, directionProduto);

  }, [pageProduto, limitProduto, debounceTermProduto, orderProduto, directionProduto]);

  useEffect(() => {

  }, [totalRegistrosPontuacao, totalRegistrosTroca, totalRegistrosProduto, totalRegistrosNotificacao]);

  useEffect(() => {
    if (props.cliente.usuario) {
      setImagem(props.cliente.usuario.caminhoImagem)
    }
  }, [props.cliente.usuario, open]);

  useEffect(() => {
    if (props.cliente.usuario) {
      setImagem(props.cliente.usuario.caminhoImagem)
    }
  }, []);

  useEffect(() => {
    if (props.cliente.idEstado) {
      dispatch(cidadeAction.getCidadeByIdEstado(props.cliente.idEstado))
    }
  }, [props.cliente.idEstado])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [open, imagem]);

  const handleChange = (prop) => (event) => {
    dispatch(clienteAction.onChangeProps(prop, event))
  }

  const handleChangePagePontuacao = (event, newPage) => {
    setPagePontuacao(newPage);
  };

  const handleChangePageTroca = (event, newPage) => {
    setPageTroca(newPage);
  };

  const handleChangePageNotificacao = (event, newPage) => {
    setPageNotificacao(newPage);
  };

  const handleChangePageProduto = (event, newPage) => {
    setPageProduto(newPage);
  };

  const handleChangeLimitPontuacao = (event) => {
    setLimitPontuacao(parseInt(event.target.value, 10));
    setPagePontuacao(0);
  };

  const handleChangeLimitNotificacao = (event) => {
    setLimitNotificacao(parseInt(event.target.value, 10));
    setPageNotificacao(0);
  };

  const handleChangeLimitTroca = (event) => {
    setLimitTroca(parseInt(event.target.value, 10));
    setPageTroca(0);
  };

  const handleChangeLimitProduto = (event) => {
    setLimitProduto(parseInt(event.target.value, 10));
    setPageProduto(0);
  };

  const handleChangePontuacao = prop => event => {
    setTermPontuacao(event.target.value);
  };

  const handleChangeTroca = prop => event => {
    setTermTroca(event.target.value);
  };

  const handleChangeProduto = prop => event => {
    setTermProduto(event.target.value);
  };

  const handleChangeNotificacao = prop => event => {
    setTermNotificacao(event.target.value);
  };

  const handleSaveObservacao = () => {
    const isEditing = !isEditingObservacao

    setIsEditingObservacao(isEditing)

    if (!isEditing) {
      dispatch(clienteAction.editClienteInfo(params.id, props.cliente))
    }
  }

  const handleClickOpenModalClientesTransferirPontos = () => {
    dispatch(clienteAction.openModalClientesTransferirPontos(true))
  }

  const handleClickOpenModalClientesAdicionarCupomManualmente = () => {
    dispatch(clienteAction.openModalClientesAdicionarPontosManualmente(true))
  }

  const handleClickOpenModalClientesAdicionarTrocasManualmente = () => {

    if (idLoja === '' || idLoja === undefined) {
      toast.error('Selecione uma Loja');
    }
    else {
      dispatch(clienteAction.openModalClientesAdicionarTrocasManualmente(true))
    }
    
  }

  const handleDeletePontuacao = (id) => {
    confirm({
      title: 'Você deseja excluir esta pontuação?',
      description: 'Essa operação é irreversível',
      disabled: props.pontuacao.isLoading,
      confirmationText: props.pontuacao.isLoading ? 'Excluindo pontuação...' : 'Sim, excluir!',
    }).then(() => {
      let filterModel = {
        Page: pagePontuacao,
        Limit: limitPontuacao,
        Term: termPontuacao,
        Order: orderPontuacao,
        Direction: directionPontuacao,
        IdCliente: params.id
      };

      dispatch(pontuacaoAction.deletePontuacaoById(filterModel, id))
    })
  }

  const handleDeleteTroca = (id) => {
    confirm({
      title: 'Você deseja excluir esta troca?',
      description: 'Essa operação é irreversível',
      disabled: props.troca.isLoading,
      confirmationText: props.troca.isLoading ? 'Excluindo troca...' : 'Sim, excluir!',
    }).then(() => {
      let filterModel = {
        Page: pageTroca,
        Limit: limitTroca,
        Term: termTroca,
        Order: orderTroca,
        Direction: directionTroca,
        IdCliente: params.id
      };

      dispatch(trocaAction.deleteTrocaById(filterModel, id))
    })
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Cliente'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Cliente'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Clientes - Editar Cliente'
          : 'Gerência de Clientes - Novo Cliente'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>

          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                directionProduto="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploading
              maxFileSize={2000000}
              onError={(errors) => errorsUploadImage(errors)}
              value={images}
              dataURLKey="data_url"
            >
              {({ onImageUpload, isDragging, dragProps }) => (
                <Card {...rest} className={clsx(classes.root, className)}>
                  <CardContent {...dragProps} className={classes.content}>
                    {isDragging ? (
                      <CloudUploadTwoToneIcon
                        color="primary"
                        style={{ fontSize: 80 }}
                      />
                    ) : (
                      <Avatar
                        key={(imagem != '' && imagem != null ? imagem.substr(imagem.lastIndexOf('/'), imagem.length - imagem.lastIndexOf('/')) : '')}
                        onClick={onImageUpload}
                        variant="rounded"
                        className={classes.avatar}
                        //remover cache = new Date().getTime()} 
                        src={imagem}
                      >
                        {getInitials((props.cliente.usuario ? props.cliente.usuario.nome : ''))}
                      </Avatar>
                    )}
                  </CardContent>
                </Card>
              )}
            </ImageUploading>
          </Grid>
        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formCliente"
              >
                <CardHeader
                  subheader="Perfil do Cliente"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome"
                        margin="normal"
                        value={(props.cliente.usuario ? props.cliente.usuario.nome.toUpperCase() : '')}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="sobrenome"
                        label="Sobrenome"
                        margin="normal"
                        value={(props.cliente.usuario ? props.cliente.usuario.sobrenome.toUpperCase() : '')}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="sexo"
                        label="Sexo"
                        margin="normal"
                        value={props.cliente.sexo == 'F' ? 'FEMININO' : props.cliente.sexo == 'M' ? 'MASCULINO' : props.cliente.sexo == null ? 'CADASTRO INCOMPLETO' : props.cliente.sexo == ' ' ? 'NÃO INFORMADO' : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="E-mail"
                        value={(props.cliente.usuario ? props.cliente.usuario.email.toLowerCase() : '')}
                        className={classes.textField}
                        id="email"
                        name="email"
                        autoComplete="email"
                        validators={['required', 'isEmail']}
                        errorMessages={[
                          'Você precisa informar o e-mail',
                          'E-mail inválido'
                        ]}
                      />
                    </Grid>
                    {props.cliente.usuario && !props.cliente.usuario.emailVerificado ?
                      <>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="E-mail verificado"
                            value="NÃO"
                            className={classes.textField}
                            id="emailVerificado"
                            name="emailVerificado"
                            readOnly
                          />
                        </Grid>
                        <AccessControl
                          rule={'clientesEmail.verify'} //permissão necessária para acessar conteúdo
                          yes={() => (
                            <Grid item lg={1} md={1} xl={1} xs={12} style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                              <IconButton
                                aria-label="VerifyEmail"
                                size="small"
                                onClick={() => handleVerificaEmailCliente(params.id)}
                              >
                                <Tooltip title="Verificar E-mail">
                                  <DoneTwoToneIcon />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                          )}
                        />
                      </>
                      :
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="E-mail verificado"
                          value="SIM"
                          className={classes.textField}
                          id="emailVerificado"
                          name="emailVerificado"
                          readOnly
                        />
                      </Grid>
                    }
                    <Grid item lg={2} md={6} xl={2} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="estado"
                        label="Estado"
                        margin="normal"
                        value={props.cliente.idEstado}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idEstado"
                      >
                        <MenuItem disabled value="">
                          <em>Estados</em>
                        </MenuItem>
                        {!isEmpty(estados) &&
                          undefined !== estados &&
                          estados.length &&
                          estados.map((row) => (
                            <MenuItem key={row.value} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="cidade"
                        label="Cidade"
                        margin="normal"
                        value={props.cliente.idCidade}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idCidade"
                      >
                        <MenuItem disabled value="">
                          <em>Cidade</em>
                        </MenuItem>
                        {!isEmpty(cidade) &&
                          undefined !== cidade &&
                          cidade.length &&
                          cidade.map((row) => (
                            <MenuItem key={row.value} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cep"
                        label="CEP"
                        margin="normal"
                        value={cepMask(props.cliente.cep) || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={8} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="endereco"
                        name="endereco"
                        label="Endereço"
                        margin="normal"
                        value={(props.cliente.endereco ? props.cliente.endereco.toUpperCase() : '')}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="bairro"
                        label="Bairro"
                        margin="normal"
                        value={props.cliente.bairro ? props.cliente.bairro.toUpperCase() : ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={3} md={6} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="data_nascimento"
                        label="Data de Nascimento"
                        margin="normal"
                        value={props.cliente.dataNascimento ? moment(props.cliente.dataNascimento).format("DD/MM/YYYY") : ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={3} md={6} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cpf"
                        label="CPF"
                        margin="normal"
                        value={cpfMask(props.cliente.cpf) || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    {(props.cliente.usuario ?
                      <>
                        <Grid item lg={3} md={6} xl={3} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="data_hora_cadastro"
                            label="Data/Hora do Cadastro"
                            margin="normal"
                            value={props.cliente.usuario.dataHoraCadastro ? moment(props.cliente.usuario.dataHoraCadastro).format("DD/MM/YYYY HH:mm") : ''}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                        <Grid item lg={3} md={12} xl={3} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="data_hora_ultimo_acesso"
                            label="Data/Hora do Último Acesso"
                            margin="normal"
                            value={props.cliente.usuario.dataHoraUltimoAcesso ? moment(props.cliente.usuario.dataHoraUltimoAcesso).format("DD/MM/YYYY HH:mm") : ''}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                      </>
                      :
                      null
                    )}
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={4} md={6} xl={3} />
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item sm={12} md={12} xs={12} xl={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formNotificacao"
              >
                <CardHeader
                  subheader="Informações sobre o(s) telefone(s) do cliente"
                  title={
                    <Typography variant="h4">
                      <b>{'Telefones'}</b>
                    </Typography>
                  }
                />
                <Divider />
                <Grid item lg={12} md={12} xl={12} xs={12} style={{
                  justifyContent: 'left', alignContent: 'center',
                  marginLeft: '12px', marginRight: '12px',
                }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Número</TableCell>
                          <TableCell>Verificado</TableCell>
                          <AccessControl
                            rule={'clientesTelefones.verify'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <TableCell align="center">Ações</TableCell>
                            )}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.cliente.clientesTelefones && props.cliente.clientesTelefones.length > 0
                          && props.cliente.clientesTelefones != null ? props.cliente.clientesTelefones.map((n) => {
                            return (
                              <TableRow hover >
                                <TableCell lg={12} md={12} xl={12} xs={12}
                                  style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                  {(n.telefone ? phoneMask(n.telefone.slice(-11)) : '')}
                                </TableCell>
                                <TableCell lg={12} md={12} xl={12} xs={12}
                                  style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                  {n.telefoneVerificado == true
                                    ?
                                    'SIM'
                                    :
                                    'NÃO'
                                  }
                                </TableCell>
                                <TableCell lg={12} md={12} xl={12} xs={12} align="center">
                                  <IconButton
                                    aria-label="SendWhatsApp"
                                    size="small"
                                    href={'http://wa.me/' + n.telefone}
                                    target="_blank"
                                  >
                                    <Tooltip title="Enviar mensagem">
                                      <WhatsAppIcon />
                                    </Tooltip>
                                  </IconButton>
                                  {(n.telefoneVerificado == false || n.telefoneVerificado == null) &&
                                    <AccessControl
                                      rule={'clientesTelefones.verify'} //permissão necessária para acessar conteúdo
                                      yes={() => (
                                        <IconButton
                                          aria-label="VerifyTelephone"
                                          size="small"
                                          onClick={() => handleVerificaTelefoneCliente(n)}
                                        >
                                          <Tooltip title="Verificar Telefone">
                                            <DoneTwoToneIcon />
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                    />
                                  }
                                </TableCell>
                              </TableRow>
                            )
                          })
                          : <TableRow>
                            <TableCell colSpan={2}>
                              <Typography align="center" variant="subtitle2">
                                Nenhum número vinculado até o momento
                              </Typography>
                            </TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Divider />
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={4} md={6} xl={3} />
        <AccessControl
          rule={'clientesObservacao'} //permissão necessária para acessar conteúdo
          yes={() => (
            <>
              <Grid item lg={8} md={6} xl={9} xs={12}>
                <Grid item sm={12} md={12} xs={12} xl={12}>
                  <Card>
                    <CardHeader
                      subheader="Observação sobre o cliente"
                      title={
                        <Typography variant="h4">
                          <b>{'Observação'}</b>
                        </Typography>
                      }
                    />
                    <Divider />
                    <Grid item lg={12} md={12} xl={12} xs={12} style={{
                      justifyContent: 'left', alignContent: 'center',
                      marginLeft: '12px', marginRight: '12px',
                    }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item lg={11} md={11} xl={11} xs={11}>
                          {
                            isEditingObservacao ?
                              <TextField
                                className={classes.textField}
                                fullWidth
                                id="observacao"
                                label="Observação"
                                InputLabelProps = {{
                                  shrink: true
                                }}
                                inputProps={{
                                  maxLength: 200,
                                }}
                                margin="normal"
                                value={props.cliente.observacao}
                                onChange={handleChange('observacao')}
                                variant="outlined"
                              />
                            :
                              <Typography style={{ margin: 5 }}>
                                {props.cliente.observacao ?? ''}
                              </Typography>
                          }
                        </Grid>
                        <Grid item lg={1} md={1} xl={1} xs={1}>
                          <IconButton color="primary" onClick={handleSaveObservacao}>
                            {isEditingObservacao ? <CheckIcon /> : <EditIcon /> }
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Card>
                </Grid>
              </Grid>
              <Grid item lg={4} md={6} xl={3} />
            </>
          )}
        />
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item sm={12} md={12} xs={12} xl={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formNotificacao"
              //onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  actAsExpander={true}
                  showExpandableButton={true}
                  subheader={"Pontuação atual: " + props.cliente.pontuacao + ".\n\n Pontuação total: " + props.cliente.pontuacaoTotal + "."}
                  title={
                    <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Typography variant="h4">
                        <b>{'Últimas Pontuações'}</b>
                      </Typography>
                      <div>
                        <AccessControl
                          rule={'clientes.transfer'} //permissão necessária para acessar conteúdo
                          yes={() => (
                            <Button
                              onClick={() => handleClickOpenModalClientesTransferirPontos()}
                              variant="contained"
                              color="primary"
                            >
                              Transferir pontos
                            </Button>
                          )}
                        />
                        <AccessControl
                          rule={'clientes.transfer'} //permissão necessária para acessar conteúdo
                          yes={() => (
                            <Button
                              onClick={() => handleClickOpenModalClientesAdicionarCupomManualmente()}
                              variant="outlined"
                              color="primary"
                            >
                              Adicionar pontos manualmente
                            </Button>
                          )}
                        />
                      </div>
                    </Grid>
                  }
                />
                <Divider />
                <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'left', alignContent: 'center', marginLeft: '12px', marginRight: '12px' }}>
                  <Grid item xl={4} lg={4} md={6} xs={12} style={{ marginTop: '12px' }}>
                    <Input
                      id="termPontuacao"
                      label="termPontuacao"
                      placeholder="Filtro"
                      value={termPontuacao}
                      onChange={handleChangePontuacao('termPontuacao')}
                      fullWidth
                    />
                  </Grid>
                  <br />
                  <TableContainer>
                    <Table >
                      <TableHead>
                        <TableRow>
                          <TableCell>Data/Hora</TableCell>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Pontos</TableCell>
                          <TableCell>Status</TableCell>
                          <AccessControl
                            rule={'fidelizacoes.delete'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <TableCell>Ações</TableCell>
                            )}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {undefined !== props.pontuacao.pontuacao && props.pontuacao.pontuacao.length
                          ? props.pontuacao.pontuacao.map((n) => {
                            return (
                              <TableRow hover key={n.id}>
                                <TableCell align="left" lg={12} md={12} xl={12} xs={12} >
                                  {moment(n.dataHora).format("DD/MM/YYYY HH:mm")}
                                </TableCell>
                                <TableCell align="left" lg={12} md={12} xl={12} xs={12} >
                                  {n.fidelizacao && n.fidelizacao.cupom ? n.fidelizacao.cupom.loja.nome : n.campanha ? n.campanha.nome : n.idTipoAjustePontuacao != 0 ? <span style={{ display: 'flex', alignItems: 'center' }}>ADICIONADO MANUALMENTE {n.tipoAjustePontuacao ? <Tooltip
                                    title={n.tipoAjustePontuacao ? n.tipoAjustePontuacao.nome : null}
                                  >
                                    <HelpIcon style={{ paddingLeft: 4, fontSize: 21, alignItems: 'center' }} />
                                  </Tooltip> : ''}
                                  </span> : ''}
                                </TableCell>
                                <TableCell align="left" lg={12} md={12} xl={12} xs={12}>
                                  {(n.fidelizacao && n.pontos ? n.pontos : n.campanha ? n.campanha.pontos : n.pontos ? n.pontos : '')}
                                </TableCell>
                                <TableCell align="left" lg={12} md={12} xl={12} xs={12}>
                                  {n.status && n.status == true || n.status == null ? 'PROCESSADO' : 'PENDENTE'}
                                </TableCell>
                                <AccessControl
                                  rule={'fidelizacoes.delete'} //permissão necessária para acessar conteúdo
                                  yes={() => (
                                    <TableCell align="center" lg={12} md={12} xl={12} xs={12}>
                                      <IconButton
                                        aria-label="Delete"
                                        onClick={() => handleDeletePontuacao(n.id)}
                                        size="small"
                                      >
                                        <Tooltip title="Excluir">
                                          <DeleteIcon />
                                        </Tooltip>
                                      </IconButton>
                                      {n.tipo != "C" ?
                                        <IconButton
                                          aria-label="visualizarPontuacao"
                                          onClick={() => handleOpenModal(n.fidelizacao ? n.fidelizacao.id : 0)}
                                          size="small"
                                        >
                                          <Tooltip title="Ver detalhes">
                                            <VisibilityTwoToneIcon />
                                          </Tooltip>
                                        </IconButton>
                                        : null}
                                    </TableCell>
                                  )}
                                />
                              </TableRow>
                            )
                          })
                          : <TableRow>
                            <TableCell colSpan="5">
                              <Typography align="center" variant="subtitle2">
                                Nenhuma pontuação até o momento
                              </Typography>
                            </TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={totalRegistrosPontuacao}
                    page={pagePontuacao}
                    onPageChange={handleChangePagePontuacao}
                    rowsPerPage={limitPontuacao}
                    onRowsPerPageChange={handleChangeLimitPontuacao}
                  />
                </Grid>
                <Divider />
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>


        <Grid item lg={4} md={6} xl={3} />
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item sm={12} md={12} xs={12} xl={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formNotificacao"
              //onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader={"Pontuação atual: " + props.cliente.pontuacao}
                  title={
                    <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Typography variant="h4">
                        <b>{'Últimas Trocas'}</b>
                      </Typography>
                      <AccessControl
                        rule={'trocas.create'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <Button
                            onClick={() => handleClickOpenModalClientesAdicionarTrocasManualmente()}
                            variant="outlined"
                            color="primary"
                          >
                            Adicionar trocas manualmente
                          </Button>
                        )}
                      />
                    </Grid>
                  }
                />
                <Divider />
                <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'left', alignContent: 'center', marginLeft: '12px', marginRight: '12px' }}>
                  <Grid item xl={4} lg={4} md={6} xs={12} style={{ marginTop: '12px' }}>
                    <Input
                      id="termTroca"
                      label="termTroca"
                      placeholder="Filtro"
                      value={termTroca}
                      onChange={handleChangeTroca('termTroca')}
                      fullWidth
                    />
                  </Grid>
                  <br />
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Data/Hora</TableCell>
                          <TableCell>Loja</TableCell>
                          <TableCell>Troca</TableCell>
                          <TableCell>Status</TableCell>
                          <AccessControl
                            rule={'trocas.delete'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <TableCell>Ações</TableCell>
                            )}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {undefined !== props.troca.troca && props.troca.troca.length
                          ? props.troca.troca.map((n) => {
                            return (
                              <TableRow hover key={n.id}>
                                <TableCell align="left" lg={12} md={12} xl={12} xs={12} >
                                  {moment(n.dataHora).format("DD/MM/YYYY HH:mm")}
                                </TableCell>
                                <TableCell align="left" lg={12} md={12} xl={12} xs={12}>
                                  {(n.loja ? n.loja.nome : '')}
                                </TableCell>
                                <TableCell align="left" lg={12} md={12} xl={12} xs={12}>
                                  {(n.produto ? n.produto.nome : '')}
                                </TableCell>
                                <TableCell align="left" lg={12} md={12} xl={12} xs={12}>
                                  {n.status && n.status == true || n.status == null ? 'PROCESSADO' : 'PENDENTE'}
                                </TableCell>
                                <AccessControl
                                  rule={'trocas.delete'} //permissão necessária para acessar conteúdo
                                  yes={() => (
                                    <TableCell align="center" lg={12} md={12} xl={12} xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                      <IconButton
                                        aria-label="Delete"
                                        onClick={() => handleDeleteTroca(n.id)}
                                        size="small"
                                      >
                                        <Tooltip title="Excluir">
                                          <DeleteIcon />
                                        </Tooltip>
                                      </IconButton>
                                      {n.status == false &&
                                        <AccessControl
                                          rule={'trocas.approve'} //permissão necessária para acessar conteúdo
                                          yes={() => (
                                            <IconButton
                                              aria-label="ApproveTrocas"
                                              size="small"
                                              onClick={() => handleAprovarTroca(n.id)}
                                            >
                                              <Tooltip title="Aprovar Troca">
                                                <DoneTwoToneIcon />
                                              </Tooltip>
                                            </IconButton>
                                          )}
                                        />
                                      }
                                    </TableCell>
                                  )}
                                />
                              </TableRow>
                            )
                          })
                          : <TableRow>
                            <TableCell colSpan="5">
                              <Typography align="center" variant="subtitle2">
                                Nenhuma troca realizada até o momento
                              </Typography>
                            </TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={totalRegistrosTroca}
                    page={pageTroca}
                    onPageChange={handleChangePageTroca}
                    rowsPerPage={limitTroca}
                    onRowsPerPageChange={handleChangeLimitTroca}
                  />
                </Grid>
                <Divider />
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>

        <Grid item lg={4} md={6} xl={3} />
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item sm={12} md={12} xs={12} xl={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formNotificacao"
              //onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Lista dos produtos favoritos do cliente"
                  title={
                    <Typography variant="h4">
                      <b>{'Produtos favoritos'}</b>
                    </Typography>
                  }
                />
                <Divider />

                <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'left', alignContent: 'center', marginLeft: '12px', marginRight: '12px' }}>
                  <Grid item xl={4} lg={4} md={6} xs={12} style={{ marginTop: '12px' }}>
                    <Input
                      id="termProduto"
                      label="termProduto"
                      placeholder="Filtro"
                      value={termProduto}
                      onChange={handleChangeProduto('termProduto')}
                      fullWidth
                    />
                  </Grid>
                  <br />
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Produto</TableCell>
                          <TableCell align="right">Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {undefined !== props.produto.produto && props.produto.produto.length
                          ? props.produto.produto.map((n) => {
                            return (
                              <TableRow hover key={n.id}>
                                <TableCell component="th" scope="row" align="right">
                                  {n.caminhoImagem ?
                                    <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                      <Avatar
                                        alt={n.nome}
                                        className={classes.avatar}
                                        src={n.caminhoImagem}
                                      />
                                      <span style={{ paddingLeft: 15, verticalAlign: 'center' }}>{n.nome} </span>
                                    </Grid>
                                    :
                                    <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                      <Avatar
                                        sx={{ bgcolor: deepOrange[500] }}
                                        alt={n.nome}
                                        src="/broken-image.jpg"
                                      />
                                      <span style={{ paddingLeft: 15, verticalAlign: 'center' }}>{n.nome} </span>
                                    </Grid>
                                  }
                                </TableCell>
                                <TableCell align="right">
                                  {n.total}
                                </TableCell>
                              </TableRow>
                            )
                          })
                          : <TableRow>
                            <TableCell colSpan="2">
                              <Typography align="center" variant="subtitle2">
                                Nenhum produto favorito até o momento
                              </Typography>
                            </TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={totalRegistrosProduto}
                    page={pageProduto}
                    onPageChange={handleChangePageProduto}
                    rowsPerPage={limitProduto}
                    onRowsPerPageChange={handleChangeLimitProduto}
                  />
                </Grid>
                <Divider />
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>

        <Grid item lg={4} md={6} xl={3} />
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item sm={12} md={12} xs={12} xl={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formNotificacao"
              >
                <CardHeader
                  subheader="Lista de notificações recebidas pelo cliente"
                  title={
                    <Typography variant="h4">
                      <b>{'Notificações recebidas'}</b>
                    </Typography>
                  }
                />
                <Divider />
                <Grid item lg={12} md={12} xl={12} xs={12} style={{
                  justifyContent: 'left', alignContent: 'center',
                  marginLeft: '12px', marginRight: '12px',
                }}>
                  <Grid item xl={4} lg={4} md={6} xs={12} style={{ marginTop: '12px' }}>
                    <Input
                      id="termNotificacao"
                      label="termNotificacao"
                      placeholder="Filtro"
                      value={termNotificacao}
                      onChange={handleChangeNotificacao('termNotificacao')}
                      fullWidth
                    />
                  </Grid>
                  <br />
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Notificação</TableCell>
                          <TableCell>Data/Hora</TableCell>
                          <TableCell align="center" style={{ display: 'flex', alignItems: 'center' }}>Data/Hora de Abertura
                            <Tooltip
                              title="Exibe a data/hora de abertura da notificação, caso ela tenha sido clicada no App"
                            >
                              <HelpIcon size={12} style={{ paddingLeft: 4 }} />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.notificacaoUsuario.notificacaoUsuario && props.notificacaoUsuario.notificacaoUsuario.length > 0
                          && props.notificacaoUsuario.notificacaoUsuario != null ? props.notificacaoUsuario.notificacaoUsuario.map((n) => {
                            return (
                              <TableRow hover >
                                <TableCell lg={12} md={12} xl={12} xs={12}
                                  style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                  {(n.notificacaoTitulo ? n.notificacaoTitulo.toUpperCase() : '')}
                                </TableCell>
                                <TableCell lg={12} md={12} xl={12} xs={12}
                                  style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                  {(n.notificacaoDataHora ? moment(n.notificacaoDataHora).format("DD/MM/YYYY HH:mm") : '')}
                                </TableCell>
                                <TableCell lg={12} md={12} xl={12} xs={12} align="left">
                                  {(n.dataHoraAbertura ? moment(n.dataHoraAbertura).format("DD/MM/YYYY HH:mm") : 'Não abriu a notificação')}
                                </TableCell>
                              </TableRow>
                            )
                          })
                          : <TableRow>
                            <TableCell colSpan={2}>
                              <Typography align="center" variant="subtitle2">
                                Nenhuma notificação recebida até o momento
                              </Typography>
                            </TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={totalRegistrosNotificacao}
                    page={pageNotificacao}
                    onPageChange={handleChangePageNotificacao}
                    rowsPerPage={limitNotificacao}
                    onRowsPerPageChange={handleChangeLimitNotificacao}
                  />
                </Grid>
                <Divider />
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>

      </Grid>

      {
        (modal ?
          <ClienteFidelizacaoModal toggle={toggle} modal={modal} idFidelizacao={idFidelizacao} pontuacao={pontuacao} props={props} />
          :
          null
        )
      }
      <ModalClientesTransferirPontos />
      <ModalClientesAdicionarPontosManualmente />
      <ModalClientesAdicionarTrocasManualmente />
    </Page >
  )
}

ClienteEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedClienteEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ClienteEdit))
)
export { connectedClienteEditPage as ClienteEdit }
